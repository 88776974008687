.form-contact {
    background: #eeeeee;
}

#contain-form {
    display: inline-block;
    /*display: flex;*/
    /*justify-content: center;*/
    margin: 20px;
    position: relative;
}

#form { /* box formulaire */
    background: radial-gradient(#333 15%, transparent 16%) 0 0, radial-gradient(#3e3e3e 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, .1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, .1) 15%, transparent 20%) 8px 9px;
    background-color: #04091987;
    background-size: 16px 16px;
    /*height: 625px;*/
    /*width: 420px;*/
    width: 350px;
    max-width: 80vw; /* TEST charles */
    padding: 30px;
    padding-bottom: 0;
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.75),
    0 0 2px rgba(0, 0, 0, 0.5),
    0 1px 1px rgba(0, 0, 0, 0.5),
    0 3px 0 #373737,
    0 4px 0 rgba(0, 0, 0, 0.5),
    0 6px 0 #373737,
    0 7px 0 rgba(0, 0, 0, 0.5),
    0 -10px 5px 10px rgba(0, 0, 0, 0.3) inset,
    0 10px 10px 15px rgba(255, 255, 255, 0.1) inset;
}

#form:before { /* couture autour du formulaire */
    content: '';
    position: absolute;
    z-index: 1;
    border: 1px dashed rgba(143, 143, 143, 0.7);
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.8), 0 0 5px 1px rgba(0, 0, 0, 0.5) inset;
}

#contain-form h1 {
    text-transform: capitalize;
    text-align: left;
    padding-left: 18px;
    margin: 0 0 30px 0;
    /*letter-spacing: 4px;*/
    font-size: 35px;
    letter-spacing: -0.06em;
}


.ribbon:before { /*  ombre du ruban tombant */
    transform: rotate(280deg);
    transform-origin: right bottom;
    border-top: 55px solid rgba(0, 0, 0, 0.3);
    border-right: 55px solid transparent;
    bottom: 30px;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: -53px;
    width: 20px;
    z-index: 10;
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.3);

}

.ribbon { /* ruban  */
    background-color: #eee;
    background-size: 5px 5px, 100% 100%;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
    border-top-right-radius: 20px 5px;
    color: #040919;
    height: 55px;
    /*width: 460px;*/
    width: 387px;
    line-height: 55px;
    padding: 0 5px 0 0;
    margin-left: -32px !important;
    position: relative;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 -25px 25px rgba(0, 0, 0, 0.2) inset, 0 0 0 2px rgba(255, 255, 255, 0.25) inset, 0 0 0 1px rgba(0, 0, 0, 0.75) inset, 0 2px 5px rgba(0, 0, 0, 0.25), 0 1px 2px rgba(0, 0, 0, 0.7), inset 4px 0 2px -1px rgba(0, 0, 0, 0.3), 0 -2px 5px rgba(0, 0, 0, 0.2);
}

fieldset {
    position: relative;
    border: 0;
    padding: 0;
    margin: 0;
    text-align: center;

}

fieldset#actions {
    display: block;
    height: 92px;
    overflow: hidden;
    position: relative;
    z-index: 100;
}

fieldset.inputs { /* contour des inputs */

    padding: 10px;
    margin-bottom: 25px;
    /*width: 400px;*/
    max-width: 77vw; /* TEST charles */
    background-color: #040919c2;
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5),
    inset 0 2px 2px 2px #1F1F1F,
    0 0 1px 1px rgba(79, 79, 79, 0.8),
    0 0 1px 1px rgba(111, 111, 111, 0.5) inset;
    border: 1px dashed rgba(143, 143, 143, 0.7);
    border-radius: 5px;
    z-index: 100;
}

@media screen and (-webkit-animation) {
    fieldset.inputs:before {
        width: 440px !important;
    }
}

fieldset#contain-select:before {
    left: 12px;
    top: 12px;
}

/*fieldset.inputs:before { !* couture autour des inputs *!*/
/*    content: "";*/
/*    padding: 0;*/
/*    margin: -13px -22px;*/
/*    height: 102%;*/
/*    width: 435px;*/
/*    max-width: 85vw; !*TEST charles*!*/
/*    position: absolute;*/
/*    border: 1px dashed rgba(143, 143, 143, 0.7);*/
/*    box-shadow: 0 0 1px 1px rgba(79, 79, 79, 0.8), 0 0 1px 1px rgba(111, 111, 111, 0.5) inset;*/
/*    border-radius: 2px;*/
/*    z-index: -1;*/
/*}*/

.inputs input { /* #f1f1f1 pictos des inputs */
    background: #0A0633 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAABkCAYAAACPQLC2AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhtJREFUeNrsV1FuwjAMbRECBAIqxD+7ARyhOwE9QvfDd28wbrB+8wNHaG9QblBuAP9IzeADAYLOntwqZQlk6z6mKZEMkRO/2o7T+plpmhplRsUoOTSABtAA/wagij+z2ayg7Pf7XfgLQGxSRSDOdrt95/dNJhOxB9frNQCxQQwSnAdSD24HvOZsgdpWzgG+J0ViWdZAFSASAESMsY0qgIMGWQ4I0FHOwW63w2w/t9vtwX6/3yjVQbPZ7IK8tVqtFCSBeW6Mc9ThGu6RheCDeBSvBfMptzZFHX2EPACZFwAajcYYYnW5c0dxQT9EkayN8xwAsisJMb4TPtqEGYDzg2vg5B6gW6UuU5kvdOVO5T2SiAfAyluAMAVDRns/c2DqBkMDaAANoAE0gAbQAH+PsdyyFQFz8amls0jNSOcjg6nKjHu93gDav5gzzEbWBjuwx5aGQI2UxTdYMBZoCPMYZATiCT0AZjIU0Z4kSV5o3SBS5slIly1q8TqdzpDWnayrl5EuS9L+xUBCGL8uA4junByfVCYDcBXLIPiSA2QjCEAxxg9a3mnBA2Ahc2QjHN21JU9em6bpHA6HTQ5Qr9fnnOuZ8ZrOHgtqlCXyeDyGBb5Qq9VExp+s5HQ6rWgeCu8CjFeB2wxc9M/n8+rhZeIqLn8yxgfGS6XbSAWx5t2+XC5L5fcBHckTHYsH4YTfeR9oxqIBNIAG+DWADwEGAAgZ5RSSVu1LAAAAAElFTkSuQmCC) no-repeat;
    padding: 0 15px 0 30px;
    margin: 0 0 10px 5px;
    width: 98%;
    border: 1px solid #ccc;
    border-radius: 5px;
    /*box-shadow: 0 1px 1px #525252 inset, 0 1px 0 #6A6A6A;*/
    height: 45px;
    box-sizing: border-box;
}

.glyph-input {
    background-position: 5px -2px !important;
}

textarea {
    /*background: #f1f1f1 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxMC45MyI+PGcgaWQ9IkNhbHF1ZV8yIiBkYXRhLW5hbWU9IkNhbHF1ZSAyIj48ZyBpZD0iQ2FscXVlXzEtMiIgZGF0YS1uYW1lPSJDYWxxdWUgMSI+PHBhdGggZD0iTS4xOC45QTEuNjgsMS42OCwwLDAsMSwxLjc5LDBIMTQuMjVhMS42OSwxLjY5LDAsMCwxLDEuNTkuOTFMMTQuNDUsMiw5LjE2LDZBMS43LDEuNywwLDAsMSw2Ljg1LDZMLjQ2LDEuMTJDLjM3LDEuMDYuMjgsMSwuMTguOVoiLz48cGF0aCBkPSJNMTAuNzgsNS43OWw1LDQuNDFhMS42NywxLjY3LDAsMCwxLTEuNS43M0gxLjc1QTEuNjYsMS42NiwwLDAsMSwuMjgsMTAuMkw1LjIyLDUuNzlsMS4xNy44N2EyLjUxLDIuNTEsMCwwLDAsMy4yMywwWiIvPjxwYXRoIGQ9Ik0wLDEuODQsNC41Myw1LjI3LDAsOS4zMVoiLz48cGF0aCBkPSJNMTEuNDgsNS4yNiwxNiwxLjg0VjkuM1oiLz48L2c+PC9nPjwvc3ZnPg==);*/
    height: 176px;
    resize: none;
    padding: 20px 10px 5px;
    width: 98%;
    margin-left: 5px;
    box-sizing: border-box;
    border-radius: 5px;
}

/*--------------------*/
#actions {
    margin: 25px 0 0 0;
}

#submit { /* bouton de connection */
    background-color: #6A078A;
    color: #eee;
    border-radius: 2px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), 0 17px 2px rgba(255, 255, 255, 0.2) inset, 0 5px 5px rgba(255, 255, 255, 0.2) inset;
    display: block;
    border: none;
    position: relative;
    float: none;
    height: 35px;
    padding: 0;
    margin: 0 auto 20px;
    width: 120px;
    cursor: pointer;
    font-size: 18px;
    text-transform: capitalize;
    bottom: -6px;
}

#submit:hover, #submit:focus {
    background-color: #2E033C;
}

#submit:active {
    outline: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}

#submit::-moz-focus-inner {
    border: none;
}

#actions a {
    color: #000000;
    float: none;
    line-height: 35px;
    margin-left: 10px;
    text-decoration: none;
}

#actions a:hover {
    color: #FFFFFF;
    text-decoration: underline;
}

.option {
    margin: auto;
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0, 0, 0, .05) 10px, rgba(0, 0, 0, .05) 20px);
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 2px 2px 2px #1F1F1F inset;
    border-radius: 5px;
    width: 140px;
    position: relative;
    bottom: -39px;
    height: 50px;
}

.option:after {
    content: "";
    width: 140px;
    height: 54px;
    left: -1px;
    top: -2px;
    position: absolute;
    border: 1px dashed rgba(143, 143, 143, 0.7);
    border-bottom: none;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.8), 0 0 1px 1px rgba(0, 0, 0, 0.5) inset;
    border-radius: 2px;
    z-index: -1;
}

.contain-messagerie {
    display: flex;
    justify-content: space-evenly;
}

.contain-message {
    width: 367px;
    position: relative;
    right: -90px;
    margin-top: 50px;
}

.message {
    max-width: 310px;
    background-color: #352929;
    padding: 12px;
    border: 1px solid #c34500;
    color: white;
    margin-bottom: 4px;
    box-shadow: 0 0 2px 2px grey;
}

input, textarea, select {
    background-color: #0A0633;
    color: white;
    /*background-color: #fff;*/
    /*border-color: #e8c291;*/
    outline: none;
    /*box-shadow: 0 0 0 1px #e8c291 inset;*/
}

input:invalid, textarea:invalid {
    border: 1px solid #6A0716;
    /*var(--invalid-color)*/
}

input:valid, textarea:valid {
    border: 1px solid #078C07;
    /*var(--valid-color)*/
}