.App-header > div {
    width: 100%;
    max-width: 1100px;
}
.App-header canvas{
    z-index: 0;
}
.App-header a{
    margin: 15px 50px;
    z-index: 5;
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    text-decoration: none;
}
.identity{
    z-index: 100;
    font-family: "Monarcha", cursive;
    margin: 10px;
}

.identity ul{
    list-style-type: none;
    list-style-position: outside;
    border-left: 5px solid white;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: 2.5em;
    font-family: var(--font-promote);
}

.identity > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.brand{
    z-index: 100;
    position: relative;
}
.brand ul {
    left: 35px;
    top:118px;
    position: absolute;
    list-style-type: disclosure-closed;
    color: #008a8c;
    font-size: 1.2em;
    font-weight: bold;
}
.backLum{
    position: absolute;
    z-index: 1;
}
.frontLum{
    position: absolute;
    z-index: 7;
}
.personnage{
    position: absolute;
    z-index: 5;
    opacity: 0.7;
}
@media screen and (max-width: 833px) {
    .App-header a{
        margin: 15px 5px;
    }
    .header{
        flex-wrap: wrap-reverse;
        justify-content: center !important;
    }
    .identity {
        text-align: center;
    }
    .identity div {
        text-align: left;
    }
    .identity div div {
        display: none;
    }
    .brand{
        margin-bottom: 50px;
    }
}