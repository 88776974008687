.experiences{
    position: relative;
    height: 100vh;
    background: var(--background-light);
    z-index: 1;
}
.experience-contain {
    position: relative;
    margin-left: 150px;
    padding: 50px 0 0 50px;
    border-left: 2px solid black;
    overflow-wrap: break-word;
    width: 100%;

}
.experience-contain::before{
    content: url("./fusee.svg");
    width: 50px;
    height: 110px;
    position: absolute;
    display: block;
    z-index: 50;
    bottom: 0;
    left: -29px;
}
.experience-contenu p{
    margin-left: 50px;
}
.decole-fusee::before{
    animation: fusee 5s forwards linear;
}
.experiences .fleche{
    margin-left: 50px;
}
.experiences #cross .circle {
    stroke: white;
}
@keyframes fusee {
    0%{
        bottom: 100%;
        transform: rotate(180deg);
        left: -22px;
    }
    90%{
        transform: rotate(180deg);
        left: -22px;
    }
    100%{
        bottom: 0;
        left: -29px;
    }
}
.date {
    font-size: 2.5em;
    font-family: var(--font-promote);
}
.date:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background: black;
    border-radius: 50%;
    top: 61px;
    left: -13.5px;
    z-index: 1;
}
.experience-name {
    font-size: 2.5em;
    font-family: var(--font-promote);
    padding-left: 20px;
}

.experience-aside{
    float: right;
    display: block;
    margin:auto;
}
@media screen and (max-width: 833px){
    .experience-contain {
        padding: 20px 0 50px 20px;
        overflow-wrap: break-word;
        width: 100%;
        margin-left: 0;
        box-sizing: border-box;
    }
    .experiences .fleche{
        height: 50px;
    }
    .experiences .fleche-top{
        top: unset;
        bottom: 0;
        right: 50px;
    }
    .experience-name {
        font-size: 1.5em;
        font-weight: lighter;
    }
    .experiences {
        min-height: 100vh;
    }
    .experience-aside {
        display: none;
    }
    div.contain-image-experience {
        max-width: 50px;
    }
    div.experience-contenu {
        margin: 0 15px 0 0;
    }
    .experience-contenu p {
        margin-left: 15px;
    }
    .experiences {
        min-height: 100vh;
    }
    .date:before{
        top: 31px;
    }
}