.footer{
    min-height: 100vh;
    background: transparent;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
}
.name{
    color: white;
    text-align: center;
    font-size: 3em;
    font-family: var(--font-promote);
    font-weight: lighter;
    letter-spacing: 3px;
}