.buble{
    position: relative;
    z-index: 100;
}
.buble img{
    position: absolute;
}

.buble .force1{
    width: 60px;
    height: 60px;
    animation: buble infinite 10s linear;
}
.buble .force2{
    width: 40px;
    height: 40px;
    animation: buble infinite 10s 1s linear;
}
.buble .force3{
    width: 20px;
    height: 20px;
    animation: buble infinite 10s 2s linear;
}
@keyframes buble {
    0%{
        transform: translate3d(0,0,0);
    }
    12%{
        transform: translate3d(-2px,-2px,0);
    }
    25%{
        transform: translate3d(0,0,0);
    }
    35%{
        transform: translate3d(5px,-5px,0);
    }
    47%{
        transform: translate3d(0,0,0);
    }
    60%{
        transform: translate3d(5px,5px,0);
    }
    72%{
        transform: translate3d(0,0,0);
    }
   84%{
        transform: translate3d(-5px,5px,0);
    }
    100%{
        transform: translate3d(0,0,0);
    }
}
.ai{
    bottom: 130px;
    right: 90px;
}
.mysql{
    bottom: 130px;
    right: -80px;
}
.symfony{
    bottom: 90px;
    right: -70px;
}
.js{
    bottom: 80px;
    right: -150px;
}
.react{
    bottom: 0px;
    right: -100px;
}
.bash{
    bottom: 30px;
    right: -170px;
}
.php{
    bottom: -80px;
    right: -150px;
}
.ps{
    bottom: -50px;
    right: -70px;
}
.android{
    bottom: -80px;
    right: -60px;
}
.java{
    bottom: -150px;
    right: -130px;
}
.ajax{
    bottom: -45px;
    right: 110px;
}
.html{
    bottom: -85px;
    right: 80px;
}
.css{
    bottom: -160px;
    right: 90px;
}
.git{
    bottom: -150px;
    right: 30px;
}