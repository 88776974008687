.contain-contact, .contain-contact a{
    text-decoration: none;
    color: white;
    text-align: center;
}
.contain-contact > ul {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 0 50px;
    grid-auto-rows: minmax(100px, auto);
    justify-content: center;
    align-items: center;
    align-content: center;
    list-style-type: none;
    padding-left: 0;
}
.contain-contact li {
    width: 80px;
    height: 80px;
}
#link-social{
    grid-column: 1/4;
    width: 100%;
    color: white;
    font-family: var(--font-primary);
    vertical-align: center;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}
#link-social span{
   display: inline-block;
}

@media screen and (max-width: 833px){
    .contain-contact ul {
        grid-template-columns: repeat(3,0fr);
        grid-gap: 0 40px;
        grid-auto-rows: minmax(66px, auto);
    }
    .contain-contact li {
        width: 55px;
        height: 55px;
    }
    .footer > div:nth-child(2) {
        margin: 4px;
    }
    footer .name {
        font-size: 2.5em;
    }
    footer #link-social {
        font-size: 1.3em;
    }

}