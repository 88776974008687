.App {
 background: var(--background-primary-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.App-link {
  color: #61dafb;
}
/*todo si le device ne supporte pas le hover je pars du principe que se n'est pas un desktop
   je désactive donc la navigation par bloc mais cela devrait être amélioré car ce n'est pas une bonne technique
   */
/*@media (hover: hover) {*/
/*  body{*/
/*    overflow-y: hidden;*/
/*  }*/
/*}*/
@media screen and (min-width: 890px) {
  body{
    overflow-y: hidden;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
