.flex{
    display: flex;
}
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-center-column{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.flex-center-evenly{
    /*color: rgba(194,200,196,0.66);*/
    color: rgb(4, 9, 25);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.flex-center-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}