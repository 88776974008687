.fleche{
    position: absolute;
    bottom: 0;
    margin: auto;
    height: 150px;
    /*width: 100px !important;*/
    z-index: 100;
    /*width: 100%;*/
}
.fleche-top{
    top: 0;
    bottom: unset;
    transform: rotate(180deg);
}
.fleche * {
    display: block;
    width: 0;
    height: 0;
}
.fleche span:hover{

}
.fleche span:nth-child(3) {
    /*border-top: 30px solid rgba(56, 56, 56, 0.78);*/
    border-top: 30px solid var(--secondary-color-light);
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    animation: fleche infinite 1.001s linear alternate-reverse;
    z-index: 3;
}
.fleche span:nth-child(2) {
    position: relative;
    top: 10px;
    /*border-top: 20px solid rgba(103, 103, 103, 0.78);*/
    border-top: 20px solid var(--secondary-color-light);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    animation: fleche infinite 1.002s linear alternate-reverse;
    z-index: 5;
}
.fleche span:first-child {
    position: relative;
    top: 15px;
    /*border-top: 10px solid rgba(176, 176, 176, 0.78);*/
    border-top: 10px solid var(--secondary-color-light);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    animation: fleche infinite 1s linear alternate-reverse;
    z-index: 8;
}

@keyframes fleche  {
    0%{
        transform: translateY(-10px) scale(1);
    }
    100%{
        transform: scale(1.1);
    }
}