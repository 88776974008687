:root{
    --background-primary-color: rgba(4,9,25,1);
    --secondary-color: rgb(106, 7, 138);
    --secondary-color-light: rgba(106, 7, 138, 0.78);
    --background-light: #fff;
    --primary-color: #D20021;
    --font-primary: "Monarcha", cursive;
    --font-promote: "charcuterie-etched", serif;
    --color-border-round : #282c34;

    --valid-color: #00FF00;
    --invalid-color: #D20021;
}

