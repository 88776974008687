.s80{
    width: 80px;
    height: 80px;
}
.s150{
    width: 150px;
    height: 150px;
}
.s250{
    width: 250px;
    height: 250px;
}
.s350{
    width: 350px;
    height: 350px;
}
.s500{
    width: 500px;
    height: 500px;
}
.h150{
    height: 150px;
}
.h250{
    height: 250px;
}
.w250{
    width: 250px;
}
.max-w200{
    max-width: 200px;
}
.max-w130{
    max-width: 130px;
}