/* TOGNOL Charles 2019
ANIMATION de la croix pivote au survol avec l'aparition d'un cercle*/
/*Position de la croix */
#cross {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 100;
}

#cross:hover .leftLine{
    transform-origin: center;
    animation: cross 0.5s ease-in-out 0s alternate backwards;
}

#cross:hover .rightLine{
    transform-origin: center;
    animation: cross 0.5s ease-in-out 0s alternate backwards;

}
#cross .circle{
    transform-origin: center;
    transform: scale(-1);
}
#cross:hover .circle{
    animation: circle 0.5s ease-in-out 0s alternate backwards;
}

@keyframes cross{
    to {
        transform: rotate(90deg);
    }
}

@keyframes circle {
    from{
        transform: scale(0.5);
    }
    to{
        stroke: black;
    }
}