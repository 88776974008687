.contain-progressBar{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
progress.progressBar{
    position: absolute;
    appearance: none;
    height: 12px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 2px;
    background: #333;
    background: -webkit-linear-gradient(#2d2d2d,#444);
    background: -moz-linear-gradient(#2d2d2d,#444);
    background: -o-linear-gradient(#2d2d2d,#444);
    background: linear-gradient(#2d2d2d,#444);
    border: 1px solid rgba(0,0,0,0);
    border-radius: 15px;
    /*box-shadow: 0 1px 0 rgba(255,255,255,.2);*/
}

/* Style de la barre pour Chrome*/

progress::-webkit-progress-bar{
    background: transparent;
}

/* Style de la barre pour Firefox*/
progress::-webkit-progress-value{
    border-radius:10px;
    background: repeating-linear-gradient(
            45deg,
            rgba(255,255,255,.2) 0,
            rgba(255,255,255,.2) 10px,
            rgba(255,255,255,0) 10px,
            rgba(255,255,255,0) 20px
    ),
    linear-gradient(
            rgba(255,255,255,.1) 50%,
            rgba(255,255,255,0) 60%
    ),
    var(--secondary-color);

}
/* Obliger de dupliquer le code car mettre les deux selecteurs séparé par une virgule
ne fonctionne pas
*/
progress::-moz-progress-bar {
    border-radius: 10px;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, .2) 0,
            rgba(255, 255, 255, .2) 10px,
            rgba(255, 255, 255, 0) 10px,
            rgba(255, 255, 255, 0) 20px
    ),
    linear-gradient(
            rgba(255, 255, 255, .1) 50%,
            rgba(255, 255, 255, 0) 60%
    ),
    var(--secondary-color);
}

.progressBar ~ span.msg{
    position: relative;
    font-size: 8px;
    color: white;
    height: 12px;
}