.tr-to-white{
    background: linear-gradient(90deg, transparent 10%, rgba(255, 255, 255, 0.3));
}
.promote-text{
    font-size: 2em;
}
.promote-text-font{
    font-size: 2.5em;
    font-family: var(--font-promote);
}
.primary-text-font{
    font-size: 2.5em;
    font-family: var(--font-primary);
}
.mr-b-none{
    margin-bottom: 0;
}
.mr-80{
    margin: 80px;
}

.round-border {
    display: block;
    border: 5px solid var(--color-border-round);
    border-radius: 50%;
    padding: 10px;
}
.msg-light{
    position: relative;
    background: var(--background-light);
    z-index: 1;
    padding: 20px 50px;
}