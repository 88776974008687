.contain-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    z-index: 100;
    /*background: rgba(255, 255, 255, 0.2);*/
    background: rgba(36, 36, 36, 0.8);
    border: 2px solid black;
    border-radius: 2%;
}

.popup {
    position: relative;
    padding: 25px;
    background: #a4a4a4;
    border: 0.5px solid #eee;
    border-radius: 5px 10px;
    box-shadow: -2px -2px 5px #eee, 2px 2px 5px #979797, inset 2px 2px 5px #646464, inset -2px -2px 5px #eee;
}